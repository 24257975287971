.swiper-container {
  width: 100%;
  height: 100%;

  @include breakpoint(small only) {
    padding-bottom: 0;
  }

  &.swiper2 {
    .swiper-slide {
      text-align: center;
      font-size: 18px;
      /* Center slide text vertically */
      display: -webkit-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      -webkit-justify-content: center;
      justify-content: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      -webkit-align-items: center;
      align-items: center;
    }

    .swiper-pagination-bullets {
      bottom: -8px;
    }

    .swiper-pagination-bullet {
      width: 12px;
      height: 12px;
      display: inline-block;
      border-radius: 0;
      background: transparent;
      border: 2px solid $black;
      opacity: 0.8;
    }

    .swiper-pagination-bullet-active {
      background-color: $black;
      opacity: 1;
    }

    img {
      &.block-slider {
        width: 485px;
      }

      width: 600px;
    }

    .swiper-button-prev-1 {
      left: 50px;
      right: auto;
    }

    .swiper-button-next-1 {
      right: 50px;
      left: auto;
    }

    .swiper-button-prev-1,
    .swiper-button-next-1 {
      color: black;
      border: 2px solid $black;
      padding: 10px;
      font-size: 12px;
      width: 50px;
      height: 130px;
      display: flex;
      @include breakpoint(small only) {
        display: none;
      }

      &:after {
        font-size: 24px;
      }
    }

  }

  &.swiper-black {
    padding-bottom: 50px;
    margin-bottom: 80px;

    .swiper-slide {
      text-align: center;
      font-size: 18px;
      /* Center slide text vertically */
      display: -webkit-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      -webkit-justify-content: center;
      justify-content: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      -webkit-align-items: center;
      align-items: center;
    }

    .swiper-pagination-bullet {
      width: 12px;
      height: 12px;
      display: inline-block;
      border-radius: 0;
      background: transparent;
      border: 2px solid $black;
      opacity: 0.8;
    }

    .swiper-pagination-bullet-active {
      background-color: $black;
      opacity: 1;
    }

    img {
      width: 600px;
    }

    .swiper-button-prev-3 {
      left: 50px;
      right: auto;
      @include breakpoint(small only) {
        display: none;
      }
    }

    .swiper-button-next-3 {
      right: 50px;
      left: auto;
      @include breakpoint(small only) {
        display: none;
      }
    }

    .swiper-button-prev-3,
    .swiper-button-next-3 {
      color: $black;
      border: 2px solid $black;
      padding: 10px;
      font-size: 12px;
      width: 50px;
      height: 130px;
      display: flex;

      &:after {
        font-size: 24px;
      }
    }

  }


  &.swiper-calendar {
    height: 400px;
    margin-top: 80px;
    position: relative;

    &:after {
      position: absolute;
      bottom: 45px;
      content: '';
      display: block;
      width: 100%;
      height: 2px;
      background-color: $black;
    }

    .swiper-slide {
      position: relative;

      .slider-inner {
        height: 90%;
        border-left: dashed 3px $black;
        margin-left: 40px;
        margin-right: 40px;
        @include breakpoint(small only) {
          margin-left: 90px;
        }

        &:after {
          position: absolute;
          bottom: 10%;
          left: 36px;
          content: '';
          display: block;
          width: 10px;
          height: 10px;
          background-color: $black;
          @include breakpoint(small only) {
            left: 87px;
          }
        }

        .box-container {
          background-color: $black;
          color: $white;
          padding: 15px;
          margin-bottom: 60px;
          width: 80%;
          display: block;
          position: relative;

          .share-link {
            position: absolute;
            top: 36px;
            left: -40px;
            content: '';
            display: block;

          }

          .share-calendar {
            position: absolute;
            top: 0;
            left: -40px;
            content: '';
            display: block;
          }

          .box {
            overflow: hidden;
            hyphens: auto;

            p {
              &.meta {
                color: $white;
                font-family: $primary-font;
                font-size: rem-calc(22px);

                @include breakpoint(medium only) {
                  font-size: rem-calc(20px);
                }
              }
            }

            h3 {
              color: $white;
              font-family: $heavy-font;
              font-size: rem-calc(17px);

              @include breakpoint(medium only) {
                font-size: rem-calc(16px);
              }
            }
          }
        }
      }

      .month {
        position: absolute;
        bottom: 0;
        left: 15px;
        text-align: center;
        width: 150px;

        p {
          font-family: $heavy-font;
          font-size: rem-calc(18px);
        }
      }

    }

    .swiper-button-prev-2 {
      left: 10px;
      right: auto;
      @include breakpoint(small only) {
        display: none;
      }
    }

    .swiper-button-next-2 {
      right: 10px;
      left: auto;
      @include breakpoint(small only) {
        display: none;
      }
    }

    .swiper-button-prev-2,
    .swiper-button-next-2 {
      color: $black;
      border: 2px solid $black;
      padding: 5px;
      font-size: 12px;
      width: 30px;
      height: 130px;
      display: flex;

      &:after {
        font-size: 24px;
      }
    }
  }
}


.calendar-container {
  width: 100%;
  height: 100%;


  .calendar-carousel {
    height: 400px;
    position: relative;
    width: 100%;
    margin: 80px auto 0;

    @media only screen and (max-width: 1024px) {
      width: calc(100% - 60px);
    }

    &:after {
      position: absolute;
      bottom: 4px;
      content: '';
      display: block;
      width: 100%;
      height: 2px;
      background-color: $black;
    }

    .slick-track {
      margin: 0;
    }

    .slick-slide,
    .slick-slide > div,
    .slick-track,
    .slick-list {
      height: 100%;
    }

    .slick-next {
      right: -60px;
      border: 2px solid #000000;

      @media only screen and (max-width: 1024px) {
        right: -30px;
      }
    }

    .slick-prev {
      left: -60px;
      border: 2px solid #000000;

      @media only screen and (max-width: 1024px) {
        left: -30px;
      }
    }

    .slick-prev:before, .slick-next:before {
      font-size: 0;
      opacity: .75;
      content: '';
      display: block;
      background-repeat: no-repeat;
      background-position: center;
      background-size: 18px 22px;
      height: 22px;
      width: 100%;

      @media only screen and (max-width: 1024px) {
        height: 18px;
        background-size: 14px 18px;
      }
    }

    .slick-prev:before {
      background-image: url(../img/left.svg);
    }

    .slick-next:before {
      background-image: url(../img/right.svg);
    }

    .slick-prev, .slick-next {
      height: 150px;
      width: 30px;
      z-index: 10;

      @media only screen and (max-width: 1024px) {
        width: 20px;
        height: 110px;
      }
    }

    .calendar-item {
      position: relative;
      height: 100%;
      box-sizing: border-box;


      .slider-inner {
        height: 100%;
        border-left: dashed 3px $black;
        margin-left: 40px;


        .share-btn-block {
          position: absolute;
          top: 0;
          left: 2px;

          > div {
            display: flex;
            width: 100%;
            flex-wrap: wrap;

            a {
              width: 100%;
              outline: none;
            }
          }
        }

        &:after {
          position: absolute;
          bottom: 0;
          left: 36px;
          content: '';
          display: block;
          width: 10px;
          height: 10px;
          background-color: $black;
        }

        .box-container {
          background-color: $black;
          color: $white;
          padding: 15px;
          margin-bottom: 60px;
          width: 85%;
          display: block;
          position: relative;
          text-decoration: none;
          box-sizing: border-box;

          @media only screen and (max-width: 1300px) {
            width: 86%;
          }

          @media only screen and (max-width: 1024px) {
            padding: 10px;
          }

          :hover {
            text-decoration: none;
          }

          .share-link {
            position: absolute;
            top: 36px;
            left: -40px;
            content: '';
            display: block;

          }

          .share-calendar {
            position: absolute;
            top: 0;
            left: -40px;
            content: '';
            display: block;
          }

          .box {
            overflow: hidden;
            hyphens: auto;

            p {
              &.meta {
                color: $white;
                font-family: $primary-font;
                font-size: rem-calc(24px);
                margin-bottom: 0;

                @media only screen and (max-width: 1024px) {
                  font-size: rem-calc(24px);
                }


                @media only screen and (max-width: 640px) {
                  font-size: rem-calc(24px);
                }
              }
            }

            h3 {
              color: $white;
              font-family: $heavy-font;
              font-size: rem-calc(17px);
              margin-bottom: 0;
              line-height: rem-calc(22px);

              @include breakpoint(medium only) {
                font-size: rem-calc(16px);
              }
            }
          }
        }
      }

    }
  }
}

.custom-slider {
  .swiper2 {
    .swiper-wrapper {
     // padding-bottom: 2rem;

      .block-slider {
        @media only screen and (max-width: 960px) {
          width: 100% !important;
        }
      }
    }

    .swiper-button-prev {
      @media only screen and (max-width: 960px) {
        display: none !important;
      }
    }

    .swiper-button-next {
      @media only screen and (max-width: 960px) {
        display: none;
      }
    }
  }
}