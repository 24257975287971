.more-btn,
.close-btn {
  font-family: $heavy-font;
  font-size: rem-calc(20px);
  color: $white;
  position: relative;
  text-decoration: none;

  &.more-btn-bigeer{
    font-size: rem-calc(26px);
  }

  &:before {
    position: absolute;
    left: -35px;
    top: 16px;
    display: block;
    content: '';
    width: 20px;
    height: 3px;
    background-color: $white;
    transition: height .3s;
  }

  &:hover {
    color: $white;

    &:before {
      height: 6px;
    }
  }
}

.more-black-btn,
.close-black-btn,
.discover-black-btn {
  font-family: $heavy-font;
  font-size: rem-calc(20px);
  color: $black;
  position: relative;
  margin-left: 40px;

  &:before {
    position: absolute;
    left: -35px;
    top: 16px;
    display: block;
    content: '';
    width: 20px;
    height: 3px;
    background-color: $black;
    transition: height .3s;
  }

  &:hover {
    color: $black;

    &:before {
      height: 6px;
    }
  }
}

.beans-btn {
  font-family: $heavy-font;
  display: inline-block;
  border: 2px solid $black;
  background-color: $black;
  color:  $white;
  font-size: 20px;
  height: 43px;
  overflow: hidden;
  padding: .3rem 1rem;
  position: relative;
  text-decoration: none;
  -webkit-transition: 0.2s -webkit-transform ease-in-out;
  transition: 0.2s -webkit-transform ease-in-out;
  transition: 0.2s transform ease-in-out;
  transition: 0.2s transform ease-in-out, 0.2s -webkit-transform ease-in-out;
  will-change: transform;
  margin-top: 1rem;
  z-index: 0;

  &:after {
    background-color: white;
    border-color: $black;
    content: '';
    display: block;
    height: 100%;
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    -webkit-transform: translate(-100%, 0) rotate(10deg);
    transform: translate(-100%, 0) rotate(10deg);
    -webkit-transform-origin: top left;
    transform-origin: top left;
    -webkit-transition: 0.2s -webkit-transform ease-out;
    transition: 0.2s -webkit-transform ease-out;
    transition: 0.2s transform ease-out;
    transition: 0.2s transform ease-out, 0.2s -webkit-transform ease-out;
    will-change: transform;
    z-index: -1;
    opacity: 0;
  }

  &:hover {
    color: $black;
    border: 1px solid $black;
    -webkit-transform: scale(1.03);
    transform: scale(1.03);
    will-change: transform;
    opacity: 1!important;
    &:after {
      -webkit-transform: translate(0, 0);
      transform: translate(0, 0);
      opacity: 1!important;
    }
  }
}


.more-container {
  .more-btn {
    font-family: $heavy-font;
    color: $black;
    position: relative;
    margin-left: 40px;

    &:before {
      position: absolute;
      left: -35px;
      top: 16px;
      display: block;
      content: '';
      width: 20px;
      height: 3px;
      background-color: $black;
      transition: height .3s;
    }

    &:hover {
      color: $black;

      &:before {
        height: 6px;
      }
    }
  }
}
// Isotop Filter

.filters-button-group {
  button {
    font-size: 14px;
    color: $white;
    display: inline-block;
    padding: 3px 10px;
    background-color: $primary-color;
    border-color: 1px solid $primary-color;
    border-radius: 15px;
    margin: 0 5px 15px 0!important;

    &:hover {
      color: $primary-color;
      background-color: $white;
      border-color: 1px solid $white;
    }

    &:active,
    &:focus {
      background-color: $primary-color;
      color: $white;
    }
  }
}

// share btns
.share-buttons {
  padding: 15px;

  a {
    img {
      padding-bottom: 5px;
    }
  }

  .share-buttons-inner {
    display: none;

    a {
      text-decoration: none;
    }
  }
}

// share btns on single page
.journal-share-buttons {
  .share-calendar-inner {
    display: none;
  }

  a {
    text-decoration: none;

    &.journal-share-buttons-whatsapp {
      opacity: 0;
      transition: opacity 1s;

      &.whatsapp-calender {
        position: absolute;
        top: 116px;
        left: -1px;
        content: '';
        display: block;
      }

      svg {
        background-color: $white;
        border: 2px solid $black;
        border-radius: 6px;
        box-sizing: border-box;

        path {
          fill: $black;
        }
      }

      &.active {
        opacity: 1;
      }
    }

    &.share-black-single-page {
      text-decoration: none;

      svg {
        background-color: $black;
        border: 2px solid $black;
        border-radius: 6px;
        box-sizing: border-box;

        path {
          fill: $white;
        }
      }
    }

    &.journal-share-buttons-fb {
      opacity: 0;
      transition: opacity 1s;

      &.fb-calender {
        position: absolute;
        top: 78px;
        left: -1px;
        content: '';
        display: block;
      }

      svg {
        background-color: $black;
        border: 2px solid $black;
        border-radius: 6px;
        box-sizing: border-box;


        path {
          fill: $white;
        }
      }

      &.active {
        opacity: 1;
      }
    }

    &:hover {
      &.share-black-single-page {
        svg {
          background-color: $white;
          border: 0;

          path {
            fill: $black;
          }
        }
      }

      &.journal-share-buttons-whatsapp {
        svg {
          background-color: $black;

          path {
            fill: $white;
          }
        }
      }

      &.journal-share-buttons-fb {
        svg {
          background-color: $white;
          border: 0;

          path {
            fill: $black;
          }
        }
      }

      &.calender {
        svg {
          rect {
            fill: $white;
          }

          path {
            fill: $black;
          }
        }
      }
    }
  }
}
