.events {
  padding: 200px 10% 50px;

  @include breakpoint(medium only) {
    padding: 150px 0 50px;
  }

  @include breakpoint(small only) {
    padding: 150px 0 50px;
  }

  h1 {
    font-family: $heavy-font;
    font-size: rem-calc(24px);
    color: $black;
  }
}

.section-container {
  padding: 100px 0;

  &.presse {

    &.presse-grey-bg {
      background: #f5f5f5;
    }

    h2 {
      margin-bottom: rem-calc(50px);
    }

    .article-box {
      @include breakpoint(small only) {
        border-bottom: 1px solid rgba(0, 0, 0, 0.3);
        padding-bottom: 20px;
        margin-bottom: 20px;
      }

      .date {
        font-family: $secondary-font;
        font-size: rem-calc(16px);
      }

      .infos {
        h3 {
          font-family: $heavy-font;
          font-size: rem-calc(24px);
        }
      }

      .links {
        a.download {
          font-size: rem-calc(16px);
          margin-top: 5px;
          margin-left: 40px;
          position: relative;

          &:before {
            position: absolute;
            content: '';
            display: block;
            top: -5px;
            left: -40px;
            background: url('../img/download-icon.svg');
            background-size: 30px 30px;
            width: 30px;
            height: 30px;
          }
        }
      }
    }
  }

  &.medien-contact {
    background-color: rgba(0, 0, 0, .3);

    h3 {
      font-family: $heavy-font;
      font-size: rem-calc(22px);
    }

    .medien-contact-flex {
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: #666666;
      font-family: $secondary-font;
      font-size: rem-calc(18px);

      @include breakpoint(small only) {
        display: block;
      }

      p {
        color: #666666;
        margin-bottom: .3rem;
      }

      a {
        font-family: $secondary-font;
        font-size: rem-calc(18px);
        display: inline-block;

        &.tel {
          color: #666666;
          margin-bottom: .3rem;
          @include breakpoint(small only) {
            display: block;
          }
        }
      }

      .beans-btn {
        margin: 0;
      }
    }
  }
}

.separate-line {
  border-bottom: 2px solid $black;
}