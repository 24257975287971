.sub-menu-fixed {
  position: fixed;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  color: $white;
  z-index: 9999;
  @include breakpoint(small only) {
    display: none;
  }

  ul {
    margin-left: 0;

    li {
      list-style-type: none;
      position: relative;
      margin-right: 40px;
      text-align: right;

      &:hover {
        a {
          opacity: .8;
          font-size: rem-calc(12px);
          visibility: visible;
        }

        &:before {
          background-color: $white;
          opacity: .8;
        }
      }

      a {
        color: $white;
        font-family: $secondary-font;
        font-size: rem-calc(14px);
        text-transform: uppercase;
        visibility: hidden;
        text-decoration: none;

        &:before {
          position: absolute;
          top: 5px;
          right: -30px;
          content: '';
          width: 16px;
          height: 16px;
          border: 2px solid #ffffff;
        }

        &.sub-menu-fixed-title-kunst-und-erlebnis {
          &.active {
            &:before {
              border: 2px solid $lg-red;
              background-color: $lg-red;
            }
          }
        }

        &.sub-menu-fixed-title-genuss-und-entdeckung {
          &.active {
            &:before {
              border: 2px solid $lg-yellow;
              background-color: $lg-yellow;
            }
          }
        }

        &.sub-menu-fixed-title-schaffen-und-kreation {
          &.active {
            &:before {
              border: 2px solid $lg-dark-red;
              background-color: $lg-dark-red;
            }
          }
        }

        &.sub-menu-fixed-title-wohnen-und-wohlfühlen {
          &.active {
            &:before {
              border: 2px solid #47B9EA;
              background-color: #47B9EA;
            }
          }
        }

        &.sub-menu-fixed-title-begegnung-und-aktivitaet {
          &.active {
            &:before {
              border: 2px solid $lg-green;
              background-color: $lg-green;
            }
          }
        }

        &.sub-menu-fixed-title-freizeitkosmos {
          &.active {
            &:before {
              border: 2px solid $lg-lila;
              background-color: $lg-lila;
            }
          }
        }

        &.active {
          visibility: visible;
        }
      }

      &:before {
        position: absolute;
        top: 5px;
        right: -30px;
        content: '';
        width: 16px;
        height: 16px;
        border: 2px solid #ffffff;
      }

      &.sub-menu-fixed-title-kunst-und-erlebnis {
        &.active {
          &:before {
            border: 2px solid $lg-red;
            background-color: $lg-red;
          }
        }
      }

      &.sub-menu-fixed-title-genuss-und-entdeckung {
        &.active {
          &:before {
            border: 2px solid $lg-yellow;
            background-color: $lg-yellow;
          }
        }
      }

      &.sub-menu-fixed-title-schaffen-und-kreation {
        &.active {
          &:before {
            border: 2px solid $lg-green;
            background-color: $lg-green;
          }
        }
      }

      &.sub-menu-fixed-title-wohnen-und-wohlfühlen {
        &.active {
          &:before {
            border: 2px solid $lg-dark-red;
            background-color: $lg-dark-red;
          }
        }
      }

      &.sub-menu-fixed-title-begegnung-und-aktivitaet {
        &.active {
          &:before {
            border: 2px solid $lg-lila;
            background-color: $lg-lila;
          }
        }
      }


      &.sub-menu-fixed-title-freizeitkosmos {
        &.active {
          &:before {
            border: 2px solid $lg-lila;
            background-color: $lg-lila;
          }
        }
      }

      &.active {
        &:before {
          background-color: $white;
        }
      }
    }
  }
}

.my-front-section{
  h2 {
    font-size: rem-calc(50px);
    @include breakpoint(small only) {
      font-size: rem-calc(26px);
    }
  }
}

.sub-menu-container {
  background-color: $white;

  .sub-menu-content {
    height: 100vh;
    position: relative;


    h2 {
      font-size: rem-calc(50px);
      @include breakpoint(small only) {
        font-size: rem-calc(26px);
      }

      &.sub-menu-content-title {
        margin-top: 15%;
        color: $white;
      }
    }

    &.vision {
      background-color: $lg-red;
      z-index: 99;
      @include breakpoint(small only) {
        height: auto;
      }

      .inner {
        position: absolute;
        width: 60%;
        top: 50%;
        transform: translateY(-50%);
        border: 0;
        margin-top: 0;

        @include breakpoint(medium only) {
          width: 100%;
        }

        @include breakpoint(small only) {
          position: relative;
          padding-bottom: 60px;
          width: 100%;
        }

        p {
          font-size: rem-calc(30px);
          color: $white;

          @include breakpoint(small only) {
            font-size: rem-calc(20px);
          }
        }
      }
    }

    &.art-img-bg {
      background-position: center;
      z-index: 9;

      .inner-vision {
        border: 2px solid $lg-red;
        position: absolute;
        width: 100%;
        top: 50%;
        left: 0;
        -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
        padding: 20px;
        width: 400px;
        @include breakpoint(small only) {
          width: 300px;
        }


        h1 {
          &.intro-tiny-title {
            font-family: $heavy-font;
            font-size: rem-calc(24px);
          }
        }

        h2 {
          &.intro-title {
            font-size: 2.5rem;
            color: $lg-red;
            @include breakpoint(small only) {
              font-size: 1.625rem;
            }
          }
        }

        h3 {
          color: $lg-red;
        }

        p {
          font-size: rem-calc(24px);
          color: $white;
          margin-bottom: 0;

          @include breakpoint(small only) {
            font-size: rem-calc(20px);
          }

          &.tiny-title {
            font-family: $heavy-font;
            font-size: rem-calc(24px);
          }
        }
      }
    }

    &.art-video-bg {
      position: relative;
      overflow: hidden;
      height: 100vh;

      #artVideo {
        display: none;
        position: fixed;
        z-index: 0;
        bottom: 0;
        top: 0;
        right: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-size: 100% 100%;
        background-color: black;
        background-position: center center;
        background-size: contain;
        object-fit: cover;
        overflow: hidden;
        @include breakpoint(small only) {
          top: -7%;
          height: 115%;
        }

        &.active {
          display: block;
        }
      }

      #artVideoVision {
        position: absolute;
        bottom: 0;
        top: 0;
        right: 0;
        left: 0;
        width: 130%;
        height: 100%;
        background-size: 100% 100%;
        background-color: black;
        background-position: center center;
        background-size: contain;
        object-fit: cover;
        overflow: hidden;
        z-index: -100;
      }

      .sub-menu-content-left {
        position: unset;
      }

      .inner {
        position: absolute;
        width: 30%;
        top: 40%;
        transform: translateY(-50%);
        padding: 40px 20px;
        border: 2px solid $white;
        margin-top: 150px;
        @include breakpoint(small only) {
          width: 100%;
        }

        h2,
        h3 {
          color: $white;
        }

        h3 {
          margin-bottom: rem-calc(25px);
          font-size: 2.5rem;
        }

        p {
          font-size: rem-calc(24px);
          color: $white;

          @include breakpoint(small only) {
            font-size: rem-calc(20px);
          }
        }
      }
    }

    &.history-front {
      background-size: cover;
      background-repeat: no-repeat;
      height: auto;
      padding: 40px 0 180px;


      .inner-history {
        position: relative;
        border: 2px solid $white;
        margin-top: 30px;
        padding: 20px;
        top: auto;
        h2,
        h3 {
          color: $white;
        }

        h2 {
          &.tiny-title {
            font-family: $heavy-font;
            font-size: rem-calc(24px);
          }
        }

        h3 {
          font-size: 2.5rem;
          margin-bottom: rem-calc(25px);

          @include breakpoint(small only) {
            font-size: 1.625rem;
          }
        }

        p {
          font-size: rem-calc(24px);
          color: $white;
          margin-bottom: 0;

          @include breakpoint(small only) {
            font-size: rem-calc(20px);
          }
        }
      }
    }

    &.areal {
      background-color: #EFEFEF;
      height: auto;
      padding: 40px 0 180px;
      @include breakpoint(small only) {
        height: auto;
        padding: 40px 0 100px;
      }

      .sub-menu-content-left,
      .sub-menu-content-right{
        height: auto;
      }
      .sub-menu-content-right img {
        top: 30%;
        transform: scale(1.3);
        left: 14%;
        @media only screen and (max-width: 1340px) {
          top: 60%;
          transform: translateY(-50%);
          left: 0;
        }
      }

      h2 {
        font-size: rem-calc(50px);
        @include breakpoint(small only) {
          font-size: rem-calc(26px);
        }

        &.sub-menu-content-title {
          margin-top: 15%;
          color: $black;
        }
      }

      .inner-areal {
        position: relative;
        margin-top: 30px;
        padding: 20px;
        border: 2px solid $black;

        h2,
        h3 {
          color: $black;
        }

        p {
          font-size: rem-calc(24px);
          color: $black;
          @include breakpoint(small only) {
            font-size: rem-calc(20px);
          }
        }
      }

      .sub-menu-content-right {
        position: relative;

        img {
          position: absolute;
          top: 60%;
          left: 0;
          transform: translateY(-50%);
          width: 100%;

          @include breakpoint(small only) {
            position: relative;
            top: 0;
            margin: 20px 0;
            padding-bottom: 20px;
            transform: unset;
            padding-left: 0;
          }
        }
      }

      .more-btn {
        position: absolute;
        bottom: 80px;
        color: $black;

        &:before {
          background-color: $black;
        }

        &:hover {
          color: $black;
        }
      }
    }

    //vision page

    &.vision-intro {
      background-color: #E15B10;
      z-index: 9;

      .inner {
        position: absolute;
        width: 75%;
        top: 50%;
        transform: translateY(-50%);
        border: 0;
        margin-top: 0;
        @include breakpoint(small only) {
          width: 100%;
        }

        p {
          font-size: rem-calc(30px);
          color: $white;
          margin-bottom: 0;

          @include breakpoint(small only) {
            font-size: rem-calc(20px);
          }
        }
      }
    }

    &.pleasure {
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      z-index: 9;

      .inner {
        border: 2px solid $lg-yellow;

        h3 {
          color: $lg-yellow;
        }
      }
    }


    &.kreation {
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      z-index: 9;

      .inner {
        border: 2px solid $lg-dark-red;

        h3 {
          color: $lg-dark-red;
        }
      }
    }

    &.living {
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      z-index: 9;

      .inner {
        border: 2px solid #47B9EA;

        h3 {
          color: #47B9EA;
        }
      }
    }

    &.activity {
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      z-index: 9;

      .inner {
        border: 2px solid $lg-green;

        h3 {
          color: $lg-green;
        }
      }
    }

    &.kosmos {
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      z-index: 9;

      .inner {
        border: 2px solid $lg-lila;

        h3 {
          color: $lg-lila;
        }
      }
    }

    //general
    .inner {
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      margin-top: 30px;
      padding: 20px;
      border: 2px solid $white;
      width: 400px;
      @include breakpoint(small only) {
        width: 300px;
      }

      h2,
      h3 {
        color: $white;
      }

      h2 {
        &.tiny-title {
          font-family: $heavy-font;
          font-size: rem-calc(24px);
        }
      }

      h3 {
        font-size: 2.5rem;
        margin-bottom: rem-calc(25px);

        @include breakpoint(small only) {
          font-size: 1.625rem;
        }
      }

      p {
        font-size: rem-calc(24px);
        color: $white;
        margin-bottom: 0;

        @include breakpoint(small only) {
          font-size: rem-calc(20px);
        }

        &.tiny-title {
          font-family: $heavy-font;
          font-size: rem-calc(24px);
        }
      }
    }

    .sub-menu-content-left {
      position: relative;
      height: 100vh;
    }

    .more-btn {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      bottom: 80px;
    }
  }
}

// sliders
#kunst-und-erlebnis-slider {
  display: none;
  padding: 200px 0 100px;
  background-color: $lg-red;
  z-index: 999;
  position: relative;
  min-height: 70vh;

  .slider-inner {
    h3 {
      font-family: $primary-font;
      font-size: rem-calc(24px);
      text-transform: initial;
      color: $white;
      margin-bottom: 1rem;
    }

    p {
      color: $white;
      font-family: $heavy-font;
    }
  }

  figure {
    figcaption {
      font-family: $secondary-font;
      font-size: rem-calc(16px);
      color: $white;
      margin-top: 10px;
    }
  }

  .close-btn-container {
    margin-top: 40px;
  }
}


#genuss-und-entdeckung-slider {
  display: none;
  padding: 200px 0 100px;
  background-color: $lg-yellow;
  z-index: 999;
  position: relative;

  h3 {
    font-family: $primary-font;
    font-size: rem-calc(24px);
    text-transform: initial;
    color: $white;
    margin-bottom: 1rem;
  }

  p {
    font-family: $heavy-font;
    color: $white;
  }

  .close-btn-container {
    margin-top: 40px;
  }
}

#schaffen-und-kreation-slider {
  display: none;
  padding: 200px 0 100px;
  background-color: $lg-dark-red;
  z-index: 999;
  position: relative;


  h3 {
    font-family: $primary-font;
    font-size: rem-calc(24px);
    text-transform: initial;
    color: $white;
    margin-bottom: 1rem;
  }

  p {
    font-family: $heavy-font;
    color: $white;
    font-family: $heavy-font;
  }


  figure {
    figcaption {
      font-family: $secondary-font;
      font-size: rem-calc(16px);
      color: $white;
      margin-top: 10px;
    }
  }

  .close-btn-container {
    margin-top: 40px;
  }
}

#wohnen-und-wohlfühlen-slider {
  display: none;
  padding: 200px 0 100px;
  background-color: #47B9EA;
  z-index: 999;
  position: relative;

  h3 {
    font-family: $primary-font;
    font-size: rem-calc(24px);
    text-transform: initial;
    color: $white;
    margin-bottom: 1rem;
  }

  h3,
  p {
    color: $white;
  }

  .close-btn-container {
    margin-top: 40px;
  }
}

#begegnung-und-aktivitaet-slider {
  display: none;
  padding: 200px 0 100px;
  background-color: $lg-green;
  z-index: 999;
  position: relative;

  h3 {
    font-family: $primary-font;
    font-size: rem-calc(24px);
    text-transform: initial;
    color: $white;
    margin-bottom: 1rem;
  }

  p {
    color: $white;
  }

  figure {
    figcaption {
      font-family: $secondary-font;
      font-size: rem-calc(16px);
      color: $white;
      margin-top: 10px;
    }
  }

  .close-btn-container {
    margin-top: 40px;
  }
}

#freizeitkosmos-slider {
  display: none;
  padding: 200px 0 100px;
  background-color: $lg-lila;
  z-index: 999;
  position: relative;

  h3 {
    font-family: $primary-font;
    font-size: rem-calc(24px);
    text-transform: initial;
    color: $white;
    margin-bottom: 1rem;
  }

  p {
    color: $white;
  }

  .close-btn-container {
    margin-top: 40px;
  }
}

#pinGenuss {
  position: relative;
  height: 200vh;

  #genussVideo {
    position: fixed;
    z-index: -1;
    bottom: 0;
    top: 0;
    right: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: 100% 100%;
    background-color: black;
    background-position: center center;
    background-size: contain;
    object-fit: cover;
    overflow: hidden;
    @include breakpoint(small only) {
      top: -7%;
      height: 115%;
    }

    &.active {
      z-index: 0;
    }
  }

  .more-btn-absolute {
    position: absolute;
    width: 100%;
    left: 50%;
    transform: translateX(-50%);
    bottom: 80px;
  }
}

#pinSchaffen{
  position: relative;
  height: 200vh;

  #schaffenVideo {
    position: fixed;
    z-index: -1;
    bottom: 0;
    top: 0;
    right: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: 100% 100%;
    background-color: black;
    background-position: center center;
    background-size: contain;
    object-fit: cover;
    overflow: hidden;
    @include breakpoint(small only) {
      top: -7%;
      height: 115%;
    }

    &.active {
      z-index: 0;
    }
  }

  .more-btn-absolute {
    position: absolute;
    width: 100%;
    left: 50%;
    transform: translateX(-50%);
    bottom: 80px;
  }
}


#pinWohnen{
  position: relative;
  height: 200vh;

  #wohnenVideo {
    position: fixed;
    z-index: -1;
    bottom: 0;
    top: 0;
    right: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: 100% 100%;
    background-color: black;
    background-position: center center;
    background-size: contain;
    object-fit: cover;
    overflow: hidden;
    @include breakpoint(small only) {
      top: -7%;
      height: 115%;
    }

    &.active {
      z-index: 0;
    }
  }

  .more-btn-absolute {
    position: absolute;
    width: 100%;
    left: 50%;
    transform: translateX(-50%);
    bottom: 80px;
  }
}


#pinWohlbefinden{
  position: relative;
  height: 200vh;

  #wohlbefindenVideo {
    position: fixed;
    z-index: -1;
    bottom: 0;
    top: 0;
    right: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: 100% 100%;
    background-color: black;
    background-position: center center;
    background-size: contain;
    object-fit: cover;
    overflow: hidden;
    @include breakpoint(small only) {
      top: -7%;
      height: 115%;
    }

    &.active {
      z-index: 0;
    }
  }

  .more-btn-absolute {
    position: absolute;
    width: 100%;
    left: 50%;
    transform: translateX(-50%);
    bottom: 80px;
  }
}

#pinKosmos{
  position: relative;
  height: 200vh;

  #kosmosVideo {
    position: fixed;
    z-index: -1;
    bottom: 0;
    top: 0;
    right: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: 100% 100%;
    background-color: black;
    background-position: center center;
    background-size: contain;
    object-fit: cover;
    overflow: hidden;
    @include breakpoint(small only) {
      top: -7%;
      height: 115%;
    }

    &.active {
      z-index: 0;
    }
  }

  .more-btn-absolute {
    position: absolute;
    width: 100%;
    left: 50%;
    transform: translateX(-50%);
    bottom: 80px;
  }
}