.filter-bar {
  p {
    &.category {
      font-size: 14px;
      color: $white;
      display: inline-block;
      padding: 3px 10px;
      margin: 0;
      background-color: $primary-color;
      border-radius: 15px;
      margin: 0 5px 15px 0;
    }
  }
}
