.single-page-container {
  padding-top: 200px;
  @include breakpoint(small only) {
    padding-top: 150px;
  }

    .post-item {
      padding: 20px 20px 20px 0;
      @include breakpoint(small only) {
        padding: 20px 0;
      }

      h2 {
        &.title {
          margin-bottom: 0.3rem;
        }
      }

      p {
        &.date {
          font-size: rem-calc(18px);
          margin: 1rem 0;
        }
      }
    }

  .post-category {
    margin-top: 30px;

    p {
      &.category {
        font-family: $secondary-font;
        font-size: rem-calc(16px);
        padding: 5px;
        border: 1px solid #0a0a0a;
        display: inline-block;
        margin-right: 5px;
      }
    }
  }
  .single-page-img {
    background-size: cover;
    height: 450px;
    margin-top: 20px;
    background-position: center;
  }

  .event-infos {
    display: flex;
    justify-content: space-between;
    padding: 30px 50px 0 0;

    p {
      font-size: rem-calc(20px);
      font-family: $heavy-font;

      &.event-bullet {
        position: relative;
        span  {
          position: absolute;
          display: block;
          content: '';
          top: 8px;
          left: -20px;
          width: 8px;
          height: 8px;
          background-color: $black;
        }
      }
    }
  }

  .main-content {
    padding-top: 50px;
  }

  .more-black-btn {
    position: absolute;
    left: 49%;
    transform: translateX(-50%);

    @include breakpoint(small only) {
      left: auto;
      right: 3%;
      transform: auto;
    }
  }

  .single-page-footer-social {
    margin-top: 50px;
  }

}