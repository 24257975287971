.history {
  padding: 200px 0 50px;
  @include breakpoint(small only) {
    padding: 150px 0 50px;
  }

  h1 {
    font-family: $heavy-font;
    font-size: rem-calc(24px);
    color: $black;
  }

  h2 {
    margin-bottom: rem-calc(30px);
  }

  p {
    &.lead-text {
      font-family: $heavy-font;
      font-size: rem-calc(23px);
      margin-bottom: rem-calc(30px);

      @include breakpoint(small only){
        font-size: rem-calc(20px);
      }
    }

    &.wp-caption-text {
      font-size: rem-calc(16px);
    }
  }

  img {
    margin-bottom: rem-calc(10px);

    &.history-img-right {

      @include breakpoint(small only) {
        margin-top: 20px;
      }
    }
  }

  h3 {
    text-transform: initial;
    font-size: rem-calc(25px);
  }

  .little-img {
    display: flex;
    align-items: flex-end;
  }

  .wp-caption {
    width: 100%!important;
  }
}

.history-second-theme {
  padding: 100px 0;
  background-color: #EFEFEF;

  h2 {
    margin-bottom: rem-calc(30px);
  }

  h3 {
    text-transform: initial;
    font-size: rem-calc(25px);
  }

  p {
    &.lead-text {
      font-family: $heavy-font;
      font-size: rem-calc(23px);
      margin-bottom: rem-calc(30px);

      @include breakpoint(small only){
        font-size: rem-calc(20px);
      }
    }
  }

  img {
    &.history-second-theme-right {
      margin-bottom: 20px;
    }
  }
}


.history-third-theme {
  padding: 100px 0;

  h2 {
    margin-bottom: rem-calc(30px);
  }

  h3 {
    text-transform: initial;
    font-size: rem-calc(25px);
  }

  p {
    &.lead-text {
      font-family: $heavy-font;
      font-size: rem-calc(23px);
      margin-bottom: rem-calc(30px);

      @include breakpoint(small only){
        font-size: rem-calc(20px);
      }
    }
  }
}

