.downloads {
  padding: 200px 0 0;
  @include breakpoint(small only) {
    padding: 150px 0 20px;
  }

  h1,
  h2,
  h3 {
    color: $black;
  }

  .downloads-container {
    padding: 30px 0;
    border-bottom: 1px solid gray;
  }

  .text-editor-left {
    ul {
      li {
        font-family: $secondary-font;
        font-size: 20px;
        list-style-type: none;
        position: relative;
        padding-left: 20px;

        &:before {
          display: block;
          position: absolute;
          content: '';
          top: 10px;
          left: 0;
          width: 10px;
          height: 10px;
          background-color: $black;
        }
      }
    }
  }
}