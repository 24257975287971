.contact {
  padding: 200px 0 50px;
  @include breakpoint(small only){
    padding: 150px 0 50px;
  }

  h1 {
    font-family: $heavy-font;
    font-size: rem-calc(24px);
    color: $black;
  }
}

.logos {
  padding: 100px 0;
  background-color: #EFEFEF;

  .box {
    display: block;
    margin-bottom: 50px;
  }

  a {
    font-size: rem-calc(16);
    font-family: $secondary-font;
    text-decoration: underline;
  }

  p {
    font-size: rem-calc(20);

    span {
      font-family: $primary-font;
    }
  }
}
