.form {

  .frm_style_formidable-style.with_frm_style textarea,
  .frm_style_formidable-style.with_frm_style select,
  .frm_style_formidable-style.with_frm_style input[type=text],
  .frm_style_formidable-style.with_frm_style input[type=tel],
  .frm_style_formidable-style.with_frm_style input[type=email] {
    border-radius: 0 !important;
    border: 1px solid $black !important;
    background-color: $white !important;
    padding: 10px;
    color: $black;
  }

  .frm_style_formidable-style.with_frm_style select {
    padding: 0 24px 0 10px;
  }


  .frm_style_formidable-style.with_frm_style .frm_submit button, .frm_form_submit_style, .frm_style_formidable-style.with_frm_style .frm-edit-page-btn {
    width: auto;
    font-family: $secondary-font, sans-serif;
    font-size: 14px;
    height: auto;
    line-height: normal;
    text-align: center;
    background: #ffffff;
    border: 1px solid $white !important;
    color: #444444;
    cursor: pointer;
    font-weight: normal;
    -moz-border-radius: 4px;
    -webkit-border-radius: 4px;
    border-radius: 4px;
    text-shadow: none;
    padding: 6px 11px;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -ms-box-sizing: border-box;
    -moz-box-shadow: 0 1px 1px #eeeeee;
    -webkit-box-shadow: 0 0px 0px #eeeeee;
    box-shadow: 0 0px 0px #eeeeee;
    margin: 10px;
    margin-left: 0;
    margin-right: 0;
    vertical-align: middle;
    @include breakpoint(small only) {
      margin-bottom: 0;
    }

    &:hover {
      border: 2px solid $black !important;
    }
  }

  @include breakpoint(small only) {
    .with_frm_style .frm_submit {
      clear: both;
      text-align: center;
      padding-top: 20px;
    }
  }


  // Label display none

  .frm_style_formidable-style.with_frm_style .frm_primary_label {
    visibility: hidden !important;
    height: 6px !important;
  }

  ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: $black !important;
  }

  ::-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: $black !important;
  }

  ::-ms-input-placeholder { /* Microsoft Edge */
    color: $black !important;
  }

  .frm_button_submit {
    font-family: $heavy-font !important;
    display: inline-block !important;
    border: 2px solid $black !important;
    background-color: $black !important;
    border-radius: 0 !important;
    color: $white !important;
    font-size: 16px !important;
    height: 38px !important;
    overflow: hidden !important;
    padding: .3rem 1rem !important;
    position: relative !important;
    text-decoration: none;
    -webkit-transition: 0.2s -webkit-transform ease-in-out;
    transition: 0.2s -webkit-transform ease-in-out;
    transition: 0.2s transform ease-in-out;
    transition: 0.2s transform ease-in-out, 0.2s -webkit-transform ease-in-out;
    will-change: transform !important;
    margin-top: 1rem !important;
    z-index: 0;
    -webkit-appearance: none;

    &:after {
      background-color: $white !important;
      border-color: $black !important;
      content: '';
      display: block;
      height: 100%;
      width: 100%;
      position: absolute;
      left: 0;
      top: 0;
      -webkit-transform: translate(-100%, 0) rotate(10deg);
      transform: translate(-100%, 0) rotate(10deg);
      -webkit-transform-origin: top left;
      transform-origin: top left;
      -webkit-transition: 0.2s -webkit-transform ease-out;
      transition: 0.2s -webkit-transform ease-out;
      transition: 0.2s transform ease-out;
      transition: 0.2s transform ease-out, 0.2s -webkit-transform ease-out;
      will-change: transform;
      z-index: -1;
      opacity: 0;
    }

    &:hover {
      color: $black !important;
      border: 1px solid $black !important;
      -webkit-transform: scale(1.03) !important;
      transform: scale(1.03) !important;
      will-change: transform !important;
      opacity: 1 !important;

      &:after {
        -webkit-transform: translate(0, 0);
        transform: translate(0, 0);
        opacity: 1 !important;
      }
    }
  }
}

#mc_embed_signup {

  .mc-field-group {
    label {
      display: none!important;
    }

    &.input-group {
      display: block;
    }

    textarea,
    select,
    input[type=text],
    input[type=tel],
    input[type=email] {
      border-radius: 0 !important;
      border: 1px solid $black !important;
      background-color: $white !important;
      padding: 10px;
      color: $black;
      box-sizing: border-box;
      height: 35px;
    }

    select {
      padding: 3px 24px;
      width: 50%;
      text-align: left;
    }

    .frm_style_formidable-style.with_frm_style select {
      padding: 0 24px 0 10px;
    }
  }
}

