header {

  &.video-container {
    position: relative;
    height: 100vh;
    z-index: 99;

    #imagevideo {
      /*bottom: 0;
      top: 0;
      right: 0;
      left: 0;
      width: 130%;
      height: 100%;
      background-size: 100% 100%;
      background-color: black;
      background-position: center center;
      background-size: contain;*/
      background: url('../img/header.jpg');
      background-position: center center;
      background-size: cover;
      position: absolute;
      top: 50%;
      left: 50%;
      min-width: 100%;
      min-height: 100%;
      width: 100%;
      height: 115%;
      z-index: -100;
      transform: translateX(-50%) translateY(-50%);
      object-fit: cover;
      overflow: hidden;
      z-index: -100;
    }

    #videoImg {

      .img-bg {
        background: url('../img/header.jpg');
        background-size: cover;
        background-position: center center;
        height: 100vh;
        width: 100%;
      }

       img {
         width: 100%;
         height: 100vh;
       }
    }


    .content {
      position: absolute;
      bottom: 24%;
      width: 500px;
      padding: 40px 20px;
      border: 2px solid $white;

      @include breakpoint(medium only) {
        width: 50%;
      }

      @include breakpoint(small only) {
        bottom: 35%;
        width: 95%;
      }

      h1 {
        color: $white;
        font-size: rem-calc(40px);
        margin-bottom: rem-calc(25px);

        @include breakpoint(small only) {
          font-size: rem-calc(26px);
        }
      }

      p {
        color: $white;
        font-size: rem-calc(24px);

        @include breakpoint(small only) {
          font-size: rem-calc(20px);
        }
      }
    }

    .play-btn {
      position: absolute;
      bottom: 5%;
      width: 100%;
      @include breakpoint(small only) {
        bottom: 15%;
      }

      #myBtn {
        width: 200px;
        font-size: 18px;
        padding: 10px;
        border: none;
        color: #fff;
        cursor: pointer;
        transition: transform 0.3s;
        outline: none;

        img {
          width: 55px;

          @include breakpoint(small only) {
            width: 50px;
          }
        }

        &:hover {
          transform: scale(1.05);
        }
      }

      .text {
        color: $white;
        font-size: 20px;
        font-family: $secondary-font;
        display: inline-block;
        width: 110px;
        line-height: 1.1;
      }
    }
  }
}