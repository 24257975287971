.discover {
  background-color: #EFEFEF;

  .intro {
    padding-top: 200px;
    padding-bottom: 30px;

    @include breakpoint(small only){
      padding-top: 150px;
      padding-bottom: 30px;
    }

    h1 {
      font-family: $heavy-font;
      font-size: rem-calc(24px);
      color: $black;
    }

    h2 {
      margin-bottom: rem-calc(50px);
    }

    p {
      &.lead-text {
        font-family: $heavy-font;
        font-size: rem-calc(23px);
        margin-bottom: rem-calc(30px);
        width: 60%;

        @include breakpoint(small only){
          font-size: rem-calc(18px);
          width: 100%;
        }
      }
    }
  }

  #pinMaster {
    position: relative;
    padding-top: 100px;
    @include breakpoint(small only) {
      padding-top: 20px;
    }
  }

  #pinContainer {
    width: 100%;
    height: 100vh;
    overflow: hidden;
    position: relative;

    @include breakpoint(small only){
      height: 100vh;
    }

    .show-small {
      @include breakpoint(small only){
        background-color: $white;
        z-index: 999;
        border-bottom: 1px solid lightgray;
      }
    }

    .map-slide-content {
      position: absolute;
      height: 500px;
      top: 45%;
      width: 100%;
      transform: translateY(-50%);
      margin-top: 25px;
      @include breakpoint(small only){
        position: relative;
        top: 0;
        height: 550px;
        transform: unset;
        margin-top: 100px;
      }

      h3 {
        text-transform: capitalize;
        margin-bottom: rem-calc(30px);
      }

      .content-wrapper {
        position: relative;
        @include breakpoint(small only){
         height: 340px;;
        }

        #firstContent,
        #secondContent,
        #thirdContent {
          //height: 100%;
          width: 110%;
          position: absolute;
          top: 0;
          background-color: #EFEFEF;
          @include breakpoint(small only){
            top: 0;
            width: 99%;
          }

          h3 {
            @include breakpoint(small only){
              margin-top: 20px;
            }
          }

          p {
            @include breakpoint(small only){
              font-size: rem-calc(16px);
            }
          }
        }

        #thirdContent {
          ul {
            margin: 0;
            li {
              list-style-type: none;
              font-family: $primary-font;
              font-size: 16px;
              opacity: .6;
              transition : opacity .5s ease-in;
              cursor: pointer;

              @include breakpoint(small only){
                font-size: 14px;
              }

              &.active {
                font-size: 20px;
                opacity: 1;
                @include breakpoint(small only){
                  font-size: 16px;
                }
              }
            }
          }
        }
      }
    }

    #overlay {
      position: absolute;
      width: 100%;
      height: 100%;
      background: transparent;
      z-index: 99;
      @include breakpoint(small only){
        top: 22%;
      }
    }

    #transform {
      @include breakpoint(small only) {
        position: absolute;
        width: 100%;
        height: 100%;
        background: #EFEFEF;
        bottom: -100px;
      }
      &.layer {
        position: absolute;
        width: 100%;
        height: 100%;
        background: transparent;
        z-index: 999;
      }
    }

    .content-wrapper-right {
      height: 350px;
    }

    svg {
      display: block;
      width: 105%;
      height: 320px;
      position: absolute;
      top: 0;
      background-color: #EFEFEF;
      z-index: 999;

      @include breakpoint(medium only){
        width: 80%;
        height: auto;
        top: 0;
      }

      @include breakpoint(small only){
        width: 100%;
        height: auto;
        top: 0;
        left: 50%;
        background-color: #EFEFEF;
        transform: translateX(-50%);
      }

      @include breakpoint(large up){
        height: 500px;
      }

      #perimeter {
        opacity: 0;
      }

      #baufeld_theilerplatz,
      #baufeld_amgleis,
      #baufeld_dammstrasse,
      #baufeld_theilerstrasse,
      #baufeld_zaehlerstadt {
        opacity: 0;
        transition : fill 1s linear;
        cursor: pointer;

        #fills_2,
        #theilerplatzneu{
          path {
            fill: #D4C3D4;
          }
        }

        #fills {
          path {
            fill: #A69EBD;
          }
        }

        #fills_4 {
          path {
            fill: #B0BEAA;
          }
        }


        &:hover {
          animation: fadeIn ease 1s;
          -webkit-animation: fadeIn ease 1s;
          -moz-animation: fadeIn ease 1s;
          -o-animation: fadeIn ease 1s;
          -ms-animation: fadeIn ease 1s;

          @keyframes fadeIn {
            0% {opacity:0;}
            100% {opacity:1;}
          }

          @-moz-keyframes fadeIn {
            0% {opacity:0;}
            100% {opacity:1;}
          }

          @-webkit-keyframes fadeIn {
            0% {opacity:0;}
            100% {opacity:1;}
          }

          @-o-keyframes fadeIn {
            0% {opacity:0;}
            100% {opacity:1;}
          }

          @-ms-keyframes fadeIn {
            0% {opacity:0;}
            100% {opacity:1;}
          }

          #fills_2,
          #theilerplatzneu {
            position: relative;

            path {
              fill: #D4C3D4;
            }
          }


          #fills_4 {
            position: relative;

            path {
              fill: #B0BEAA;
            }
          }


          #fills_3 {
            position: relative;

            path {
              fill:  rgb(164, 195, 205);
            }
          }

          #fills_5 {
            position: relative;

            path {
              fill: #8A73C9;
            }
          }

          #fills {
            position: relative;

            path {
              fill: #A69EBD;
            }
          }
        }
      }
    }
  }
}

.more-about-discover-slider {
  display: none;
  width: 100%;
  padding: 110px 30px;
}

.more-about-map {
  #areal-slider,
  #baufeld_theilerplatz-slider,
  #baufeld_amgleis-slider,
  #baufeld_dammstrasse-slider,
  #baufeld_theilerstrasse-slider,
  #baufeld_zaehlerstadt-slider {
    display: none;
    width: 100%;
    padding: 110px 30px;
    background: #f7f7f7;
    min-height: 400px;

    @include breakpoint(small only) {
      min-height: auto;
      padding: 40px 30px;
    }
    //position: fixed;
    //top: 0;
    //bottom: 0;
    //right: 0;
    //left: 0;
    //overflow-y: hidden;
    //width: 100%;
    //height: 100vh;
    //background: white;

    //z-index: 999;

    .grid-container {
      position: relative;
      height: 100%;

      .slider-inner {
        position: relative;

        img {
          width: 80%;
          margin-top: 80px;
          position: absolute;
          left: 50%;
          display: block;
          transform: translateX(-50%);

          @include breakpoint(small only) {
            position: relative;
            margin-top: 0;
            margin-bottom: 25px;
          }
        }
      }

      .more-info-box {
        max-width: 300px;
        float: left;
      }

      .close-btn-container {
        margin: 40px;
        //position: absolute;
        //left: 50%;
        //transform: translateX(-50%);
        //bottom: 0;
      }
    }
  }

  #baufeld_amgleis-slider,
  #baufeld_theilerstrasse-slider {
    min-height: 600px;
  }
}

.city-modell {
  padding: 100px 0;
  @include breakpoint(small only){
    padding: 40px 0 0;
  }

  h2 {
    margin-bottom: rem-calc(30px);
  }

  p {
    &.lead-text {
      margin-top: 30px;

      @include breakpoint(small only){
        font-size: rem-calc(20px);
      }
    }
  }
}

.roadmap-timeline{
  background-color: #EFEFEF;

  .roadmap-timeline-block{
    transition: all 0.3s ease-in-out;
    position: relative;
    z-index: 1;

    //@include breakpoint(small only) {
    //  padding-bottom: 1px;
    //  margin-top: -100px!important;
    //}

    .roadmap-timeline-block-content{
      display: flex;
      align-items: center;
      padding: 0 0 45px;

      @include breakpoint(small only) {
        flex-wrap: wrap;
        padding: 65px 0;
      }

      &.more-about-map-opened{
        padding: 65px 0 45px;

        @include breakpoint(small only) {
          padding: 65px 0;
        }
      }

      .roadmap-timeline-text{
        width: 60%;

        @include breakpoint(small only) {
          width: 100%;
        }

        .roadmap-timeline-text-intro{
          margin-bottom: 65px;

          @include breakpoint(small only) {
            margin-bottom: 45px;
          }

          h3{
            font-size: rem-calc(28px);
            line-height: rem-calc(38px);
            text-transform: unset;
            margin-bottom: rem-calc(16px);

            @include breakpoint(small only) {
              font-size: rem-calc(24px);
              line-height: 1.4;
              margin-bottom: rem-calc(10px);
            }
          }

          p{
            font-size: rem-calc(23px);
            line-height: rem-calc(32px);

            @include breakpoint(small only) {
              font-size: rem-calc(20px);
              line-height: 1.4;
            }
          }
        }

        .roadmap-timeline-items{

          .roadmap-timeline-item{
            position: relative;
            padding-left: 24px;
            box-sizing: border-box;
            margin-left: 4px;
            padding-bottom: 68px;

            @include breakpoint(small only) {
              padding-bottom: 48px;
            }

            &:last-child{
              &:after{
                display: none;
              }
            }

            &:before{
              content: '';
              display: block;
              width: 12px;
              height: 12px;
              background-color: #000;
              position: absolute;
              top: 6px;
              left: -7px;
            }

            &:after{
              content: '';
              display: block;
              width: 2px;
              height: 100%;
              background-color: #000;
              position: absolute;
              top: 6px;
              left: -2px;
            }

            p{
              font-size: rem-calc(20px);
              line-height: rem-calc(27px);

              @include breakpoint(small only) {
                font-size: rem-calc(16px);
                line-height: 1.4;
              }
            }

            .roadmap-timeline-item-title{
              margin-bottom: 10px;
              font-family: $primary-font;
            }

            .roadmap-timeline-item-text{
              p:last-child{
                margin-bottom: 0;
              }
            }
          }
        }
      }

      .roadmap-timeline-image{
        width: 40%;
        box-sizing: border-box;
        display: flex;
        justify-content: flex-end;

        @include breakpoint(small only) {
          width: 100%;
        }

        img{
          width: 80%;
          height: auto;

          @include breakpoint(small only) {
            width: 100%;
          }
        }
      }
    }
  }
}