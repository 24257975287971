.modal {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin: 0 auto;
  background-color: #fff;
  width: 600px;
  max-width: 75rem;
  min-height: 20rem;
  padding: 2rem 1rem 2rem;
  border-radius: 3px;
  opacity: 0;
  overflow-y: auto;
  visibility: hidden;
  box-shadow: 0 2px 10px rgba(#000, 0.1);
  backface-visibility: hidden;
  transform: scale(1.2);
  transition: all ease-in 0.3s;

  .close-modal {
    position: absolute;
    cursor: pointer;
    top: 5px;
    right: 15px;
    opacity: 0;
    backface-visibility: hidden;
    transition: opacity ease-in 0.3s, transform ease-in 0.3s;
    transition-delay: 0.3s;
    z-index: 99999;

    @include breakpoint(small only) {
      top: 12%;
    }

    svg {
      width: 1.75em;
      height: 1.75em;
    }
  } // close modal

  .modal-content {
    opacity: 0;
    backface-visibility: hidden;
    transition: opacity ease-in 0.3s;
    transition-delay: 0.3s;
    z-index: 999;

    h2 {
      margin-bottom: 30px;
      font-size: 26px;
      text-align: center;

      @include breakpoint(small only) {
        font-size: 22px;
      }
    }

    p {
      &.newsletter-infotext {
        color: black;
        text-align: center;
        padding: 0 5%;
        font-size: 16px;
      }
    }

  } // content

  &.active {
    visibility: visible;
    opacity: 1;
    transform: scale(1);
    background: $white;
    z-index: 9999;
    @include breakpoint(small only) {
      background: #EFEFEF;
    }

    .modal-content {
      opacity: 1;
      padding: 20px;

      @include breakpoint(small only) {
        padding: 100px 21px 20px;
      }
    }

    .close-modal {
      transform: translateY(10px);
      opacity: 1;
    }
  }
}

/**
* Mobile styling
*/
@media only screen and (max-width: 39.9375em) {

  h1 {
    font-size: 1.5rem;
  }

  .modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    -webkit-overflow-scrolling: touch;
    border-radius: 0;
    transform: scale(1.1);
    padding: 0 !important;
  }

  .close-modal {
    right: 20px !important;
    z-index: 999;
  }
}

/**
 * Overlay
 * -- only show for tablet and up
 */
@media only screen and (min-width: 40em) {

  .modal-overlay {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 5;
    background-color: rgba(#000, 0.6);
    opacity: 0;
    visibility: hidden;
    backface-visibility: hidden;
    transition: opacity ease-in 0.5s, visibility ease-in 0.5s;


    &.active {
      opacity: 1;
      visibility: visible;
    }
  }

  .close-modal {
    position: absolute;
    cursor: pointer;
    top: 150px;
    right: 15px;
    opacity: 0;
    backface-visibility: hidden;
    transition: opacity ease-in 0.5s, transform ease-in 0.5s;
    transition-delay: 0.5s;
    z-index: 999;

    svg {
      width: 1.75em;
      height: 1.75em;
    }
  } // close modal

}

