$primary-font: 'Avenir LT W04_95 Black1475560';
$secondary-font: "Avenir LT W04_65 Medium1475536";
$heavy-font: "Avenir LT W04_85 Heavy1475548";

$lg-red: #FF4660;
$lg-dark-red: #D50000;
$lg-yellow: #D3BE57;
$lg-green: #78DE8F;
$lg-lila: #6960D9;

html {
  position: relative;
  overflow-x: hidden!important;
}

body {
  overflow-x: hidden;
  font-family: $secondary-font!important;
  font-size: 20px;
  $primary-color: $black;

  figcaption {
    font-size: 16px;
  }

  h1 {
    font-family: $primary-font;
    line-height: 1.1;
    color: $white;
    font-size: rem-calc(34px);
    text-transform: uppercase;
    font-smoothing: antialiased;


    @include breakpoint(small only){
      font-size: rem-calc(28px);
    }
  }

  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: $primary-font;
    line-height: 1.1;
    color: $primary-color;
    text-transform: uppercase;
    font-smoothing: antialiased;
  }

  h1,
  h2 {
    &.main-title {
      margin-bottom: 0;
    }
  }

  p {
    font-family: $secondary-font;
    font-size: 20px;
    color: $black;
    line-height: 1.4;
    margin-bottom: 1.5rem;

    &.subtitle {
      text-transform: uppercase;
      opacity: .5;
    }

    &.lead-text {
      font-family: $heavy-font;
      font-size: rem-calc(23px);
      margin-bottom: rem-calc(30px);

      @include breakpoint(small only){
        font-size: rem-calc(20px);
      }
    }
  }


  a {
    color: $primary-color;
    text-decoration: underline;

    &:hover {
      //opacity: 0.8;
      color: $primary-color;
    }
  }

  .page-wrapper {
    padding: 200px 0;
  }

  .section-container {
    padding: 100px 0;
    @include breakpoint(small only) {
      padding: 30px 0;
    }
  }

  .no-padding {
    padding: 0!important;
  }

  .more-info-box {
    border: 2px solid #979797;
    padding: 15px;
    margin-top: 30px;
    max-width: 300px;
    overflow-wrap: break-word;

    h3 {
      text-transform: initial;
      font-size: rem-calc(25px);
      color: #979797;
      margin-bottom: rem-calc(20px);
    }

    ul {
      margin: 0;
      padding: 0;

      li {
        list-style-type: none;
        margin-bottom: rem-calc(20spx);
        display: inline-block;

        a {
          &.extern-link {
            padding-left: 35px;
            position: relative;
            left: 0;
            display: block;

            &:before {
              position: absolute;
              top: -5px;
              left: 0;
              display: block;
              content: '';
              background: url('../img/extern-link-icon.svg');
              background-size: 25px 30px;
              width: 25px;
              height: 30px;
            }
          }

          &.download-link {
            padding-left: 35px;
            position: relative;
            left: 0;
            display: block;

            &:before {
              position: absolute;
              top: -5px;
              left: 0;
              display: block;
              content: '';
              background: url('../img/download-icon.svg') no-repeat;
              background-size: 25px 30px;
              width: 25px;
              height: 30px;
            }
          }
        }
      }
    }
  }
}

.loading{
  display: none;
}

.simple-page {
  padding: 200px 0 50px;
  @include breakpoint(small only) {
    padding: 150px 0 50px;
  }

  h1,
  h2,
  h3 {
    color: $black;
  }

  ul {
    li {
      font-family: $secondary-font;
      font-size: 20px;
      list-style-type: none;
      position: relative;
      padding-left: 20px;

      &:before {
        display: block;
        position: absolute;
        content: '';
        top: 10px;
        left: 0;
        width: 10px;
        height: 10px;
        background-color: $black;
      }
    }
  }
}

.geschichte-slider{
  figure{
    figcaption{
      font-family: "Avenir LT W04_65 Medium1475536";
      font-size: 16px;
      color: #0a0a0a;
      line-height: 1.4;
      margin-bottom: 1.5rem;
    }
  }
}

.swiper2{
  figure{
    figcaption{
      font-family: "Avenir LT W04_65 Medium1475536";
      font-size: 16px;
      color: #0a0a0a;
      line-height: 1.4;
    }
  }
}

.wp-block-image{
  figcaption{
    text-align: left;
  }
}