.sticky-content {
  padding-top: rem-calc(100px);

  .text-container {
    background-color: $black;
    padding: 10%;
    height: 100vh;

    p {
      color: $white;
      opacity: 0;
      display: none;
    }
  }

  .is-stuck {
    .text-container {
      background-color: $black;
      padding: 30%10% 10%;
      height: 100vh;

      p {
        color: $white;
        opacity: 1;
        display: block;
        transition: opacity .5s ease-out;
      }
    }
  }
}