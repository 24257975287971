.content2-section {
    // margin-left : (calc((100vw - 100%) / -2));
    // margin-right: (calc((100vw - 100%) / -2));

    .section-inner {
        padding: 50px 15px;
    }

    &.bg {
        &-gray {
            .section-inner {
                background-color: #efefef;
            }
        }
    }

    hr {
        margin-top   : 40px;
        margin-bottom: 40px;
    }

    .title {
        margin-bottom: 20px;
        text-transform: none;
    }

    .links-title {
        margin-bottom: 10px;
        font-weight  : 900;
        color        : $dark-gray;
    }

    .links-box {
        max-width: 360px;
        margin   : 50px 0;
        border   : 1px solid #d6d6d7;
        padding  : 20px;

        ul {
            margin         : 0;
            list-style-type: none;
        }

        li {
            margin-top   : 5px;
            margin-bottom: 5px;
        }

        a {
            position       : relative;
            text-decoration: none;
            font-size      : 1rem;
            padding-left   : 25px;
        }

        svg {
            display  : block;
            position : absolute;
            width    : 16px;
            height   : 16px;
            left     : 0;
            top      : 50%;
            transform: translateY(-50%);
        }

        .link {}

        .file {}

    }

    .mobile-box{
        display: none;
        .links-box{
            margin-top: 25px;
            margin-bottom: 0;
        }
    }
    @media print,
    screen and (max-width: map-get($breakpoints, 'medium')) {
        .desktop-box {
            display: none;
        }
        .mobile-box{
            display: block;
        }
    }
}