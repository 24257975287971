.post-item {
  h1 {
    color: $black;
    font-size: rem-calc(28px);
  }

  h2 {
    font-size: rem-calc(24px);
  }


  h3 {
    font-size: rem-calc(20px);
    text-transform: initial;
  }

  h4 {
    font-size: rem-calc(18px);
  }

  p {
    font-size: rem-calc(18px);
  }

  ul{
    li{
      font-size: rem-calc(18px);
      line-height: 1.4;
      font-family: "Avenir LT W04_65 Medium1475536";
    }
  }


  .blocks-gallery-grid {
    li {
      list-style-type: non;
      display: inline-block;
      width: 33%;
    }
  }

  .wp-block-quote {
    color: $black;
    border-left: 10px solid $black;
    margin: 1.5em 0;
    padding: 0.5em;
    position: relative;

    &:before {
      color: $black;
      content: '';
      background-image: url(../img/quote.svg);
      background-repeat: no-repeat;
      background-position: center;
      background-size: 25px 25px;
      height: 25px;
      width: 25px;
      font-size: 4em;
      display: block;
      line-height: 0.1em;
      margin-right: 0.25em;
      vertical-align: -0.4em;
      position: absolute;
      top: 0;
      left: 5px;
    }

    p {
      font-family: $primary-font;
      display: inline;
      font-size: rem-calc(20px);
      color: $black;
      margin-left: 30px;
    }

    cite {
      color: $black;
      font-family: $secondary-font;
      font-size: rem-calc(15px);

      &:before {
        content: " ";
      }
    }

  }


  .wp-block-group__inner-container,
  .wp-block-quote,
  figure {
    margin-bottom: 3rem;
    //@include xy-grid-container;
  }

  .wp-block-cover {
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
    height: 300px;
    margin-bottom: 3rem;

    .wp-block-cover__inner-container {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;

      p {
        font-size: 36px;
        font-size: $white;
      }
    }
  }

  .wp-block-button {
    a {
      display: inline-block;
      border: 2px solid $primary-color;
      border-radius: 3rem;
      background-color: $white;
      color: $primary-color;
      font-size: 20px;
      height: 44px;
      overflow: hidden;
      padding: .3rem 1rem;
      position: relative;
      text-decoration: none;
      -webkit-transition: 0.2s -webkit-transform ease-in-out;
      transition: 0.2s -webkit-transform ease-in-out;
      transition: 0.2s transform ease-in-out;
      transition: 0.2s transform ease-in-out, 0.2s -webkit-transform ease-in-out;
      will-change: transform;
      margin-top: 1rem;
      z-index: 0;

      &:after {
        background-color: $primary-color;
        border-radius: 3rem;
        border-color: $primary-color;
        content: '';
        display: block;
        height: 100%;
        width: 100%;
        position: absolute;
        left: 0;
        top: 0;
        -webkit-transform: translate(-100%, 0) rotate(10deg);
        transform: translate(-100%, 0) rotate(10deg);
        -webkit-transform-origin: top left;
        transform-origin: top left;
        -webkit-transition: 0.2s -webkit-transform ease-out;
        transition: 0.2s -webkit-transform ease-out;
        transition: 0.2s transform ease-out;
        transition: 0.2s transform ease-out, 0.2s -webkit-transform ease-out;
        will-change: transform;
        z-index: -1;
        opacity: 0;
      }

      &:hover {
        color: $white;
        border: 0px solid $primary-color;
        -webkit-transform: scale(1.05);
        transform: scale(1.05);
        will-change: transform;
        opacity: 1 !important;

        &:after {
          -webkit-transform: translate(0, 0);
          transform: translate(0, 0);
          opacity: 1 !important;
        }
      }
    }
  }

  .wp-block-embed-youtube {

    .wp-block-embed__wrapper {
      width: 100%;
      height: 500px;

      iframe {
        width: 100%;
        height: 100%;
      }
    }
  }

  .wp-block-gallery.has-nested-images figcaption {
    text-align: left;
  }
}