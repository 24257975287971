@font-face{
  font-family:"Avenir LT W04_65 Medium1475536";
  src:url("../fonts/1475536/7839a002-2019-46e4-8db8-c8335356ceee.woff2") format("woff2"),url("../fonts/1475536/28433854-a1b7-4a30-b6a4-10c3a5c75494.woff") format("woff");
}
@font-face{
  font-family:"Avenir LT W04_85 Heavy1475548";
  src:url("../fonts/1475548/e9b44b79-923f-49b3-837c-290bc70628cc.woff2") format("woff2"),url("../fonts/1475548/fbd14ec6-aa86-4ccd-af83-931d5eb79910.woff") format("woff");
}
@font-face{
  font-family:"Avenir LT W04_95 Black1475560";
  src:url("../fonts/1475560/ef5800d8-b76d-43e8-87ff-81d455db24c0.woff2") format("woff2"),url("../fonts/1475560/1144e594-fd94-44b6-9cf8-171075a034cc.woff") format("woff");
}
