.header-section {
    margin-bottom: 1.875rem;

    .suptitle {
        font-size  : 1.5rem;
        font-family: $heavy-font;
        color      : $body-font-color;

    }

    .title {
        margin-bottom: 20px;
        font-size    : 1.75rem;

    }

    .lead-text * {
        font-size: 1.25rem;
    }
    strong{
        font-family: "Avenir LT W04_85 Heavy1475548";
    }

    @media print,
    screen and (min-width: map-get($breakpoints, 'medium')) {
        .title {
            font-size: 2.5rem;
        }

        .lead-text *{
            font-size: 1.4375rem;
        }

    }
}