footer {
  background-color: $white;
  padding: 100px;
  color: $white;
  position: relative;
  z-index: 99;

  @include breakpoint(small only) {
    padding: 50px 30px;
    text-align: center;
  }

  p {
    color: $white;
    font-size: rem-calc(14px);
  }

  a {
    color: $black;
  }

  nav {
    @include breakpoint(small only) {
      margin-bottom: 20px;
    }

    ul {
      margin: 0;

      &.menu {
        display: block !important;
      }

      @include breakpoint(small only) {
        width: 100%;
        display: none;
      }

      li {
        list-style-type: none;
        margin-right: 5px;
        padding: 0.5rem 1rem;

        @include breakpoint(small only) {
          display: block;
          text-align: center;
          margin-right: 0;
        }

        a {
          color: $black;
          font-size: rem-calc(18px);
          font-family: $primary-font;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
          position: relative;
          transition: all .5s;
        }
      }
    }
  }

  .social-link {

    a {
      margin-left: 5px;

      &.social-icon-fb {
        display: inline-block;

        &:hover {
          svg {
            background: $black;
            border-radius: 5px;
            padding: 2px;

            path {
              fill: $white;
            }
          }
        }
      }

      &.social-icon-insta {
        display: inline-block;

        &:hover {
          svg {
            background: $black;
            border-radius: 5px;
            padding: 2px;

            path {
              fill: $white;
            }
          }
        }
      }
    }
  }
  .social-icon-linkedin{
    width: 33px;
    display: inline-block;
    height: 33px;
    background: url(/wp-content/themes/lgzug/src/assets/img/linkedin.svg);
    &:hover {
      background: url(/wp-content/themes/lgzug/src/assets/img/linkedin_hover.svg);
    }
  }

  .contact-infobox-container {
    position: relative;

    a {
      &.footer-logo {
        & > img {
          margin-bottom: 20px;
          object-fit: contain;
          object-position: left;
          max-width: 200px;
          max-height: 50px;

          @include breakpoint(small only) {
            width: 50px !important;
            margin-bottom: 10px;
            margin-top: 20px;
          }
        }
      }
    }

    .contact-infobox {

      p {
        color: $black;
        font-size: rem-calc(16px);
        font-family: $primary-font;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        position: relative;
        transition: all .5s;
        margin-bottom: .5rem;
      }

      a {
        font-size: rem-calc(18px);
      }

      .copyright {
        margin-top: 20px;
        position: absolute;
        bottom: 0;

        @include breakpoint(small only) {
          position: relative;
        }

      }

      @include breakpoint(small only) {
        position: relative;
        margin-top: 20px;
        width: 100%;
        margin-bottom: 30px;

        ul {
          li {
            a {
              @include breakpoint(small only) {
                left: -10px;
              }
            }
          }
        }
      }
    }
  }

  ul {
    margin: 0;

    li {
      list-style-type: none;
    }
  }

  .links {
    position: relative;
    text-align: right;
    @include breakpoint(small only) {
      text-align: center;
    }

    .modal-btn {
      position: absolute;
      bottom: 0;
      right: 0;

      @include breakpoint(small only) {
        position: relative;
      }
    }
  }
}