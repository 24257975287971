/***** Navigatipn with background ******/

.main-header-navigation-with-bg {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  z-index: 99999;
  background-color: $white;
  @include breakpoint(small only) {
    position: fixed;
    top: 0;
  }


  nav {
    ul {
      margin: 0;

      @include breakpoint(small only) {
        width: 100%;
        display: none;
      }

      li {
        list-style-type: none;
        display: inline-block;
        margin-right: 5px;

        @include breakpoint(small only) {
          display: block;
          text-align: left;
          width: 89%;
        }

        &.menu-item-has-children {
          position: relative;
          &:after {
            position: absolute;
            top: 3px;
            right: 0;
            display: block;
            content: '';
            background: url('../img/arrow-menu-icon.svg') no-repeat;
            background-size: 30px 30px;
            width: 30px;
            height: 30px;
            @include breakpoint(small only) {
              display: none;
            }
          }

          .mobile-sub {
            position: absolute;
            top: 3px;
            right: -33px;
            display: block;
            content: '';
            background: url('../img/arrow-menu-icon.svg') no-repeat;
            background-size: 30px 30px;
            width: 30px;
            height: 30px;
            z-index: 999;
          }

          a {
            padding: 0.7rem 1.6rem 0.7rem 1rem;
          }

          &:hover,
          &:focus {
            a {
              color: $white;
              @include breakpoint(small only) {
                color: $black;
                background-color: $white;
              }
            }

            &.menu-item-has-children {
              position: relative;

              &:after {
                position: absolute;
                top: 3px;
                right: 0;
                display: block;
                content: '';
                background: url('../img/arrow-menu-white-icon.svg') no-repeat;
                background-size: 30px 30px;
                width: 30px;
                height: 30px;
                @include breakpoint(small only) {
                  display: none;
                }
              }
            }
          }

          ul {
            &.sub-menu {
              display: none;
              background-color: $white;
              border: 2px solid $black;
              position: absolute;
              @include breakpoint(small only) {
                margin-left: 10px;
              }

              &.sub-menu-active {
                display: block;
              }

              li {
                display: block;
                margin-right: 0;
                width: 200px;
                @include breakpoint(small only) {
                  width: auto;
                }

                &.sub-menu-art {
                  position: relative;

                  a {
                    display: inline-block;
                    &:before {
                      position: absolute;
                      top: 13px;
                      left: 5px;
                      content: '';
                      width: 8px;
                      height: 8px;
                      border: 2px solid $lg-red;
                      z-index: 999;
                    }
                  }
                }

                &.sub-menu-discovery {
                  position: relative;

                  a {
                    display: inline-block;
                    &:before {
                      position: absolute;
                      top: 13px;
                      left: 5px;
                      content: '';
                      width: 8px;
                      height: 8px;
                      border: 2px solid $lg-yellow;
                      z-index: 999;
                    }
                  }
                }

                &.sub-menu-creation {
                  position: relative;

                  a {
                    display: inline-block;
                    &:before {
                      position: absolute;
                      top: 13px;
                      left: 5px;
                      content: '';
                      width: 8px;
                      height: 8px;
                      border: 2px solid $lg-dark-red;
                      z-index: 999;
                    }
                  }
                }

                &.sub-menu-living {
                  position: relative;

                  a {
                    display: inline-block;
                    &:before {
                      position: absolute;
                      top: 13px;
                      left: 5px;
                      content: '';
                      width: 8px;
                      height: 8px;
                      border: 2px solid #47B9EA;
                      z-index: 999;
                    }
                  }
                }

                &.sub-menu-activity {
                  position: relative;

                  a {
                    display: inline-block;
                    &:before {
                      position: absolute;
                      top: 13px;
                      left: 5px;
                      content: '';
                      width: 8px;
                      height: 8px;
                      border: 2px solid $lg-green;
                      z-index: 999;
                    }
                  }
                }

                &.sub-menu-kosmos {
                  position: relative;

                  a {
                    display: inline-block;
                    &:before {
                      position: absolute;
                      top: 13px;
                      left: 5px;
                      content: '';
                      width: 8px;
                      height: 8px;
                      border: 2px solid $lg-lila;
                      z-index: 999;
                    }
                  }
                }

                a {
                  font-size: rem-calc(14px);
                  color: $black;
                }

                &:hover,
                &:focus {
                  a {
                    color: $white;
                    @include breakpoint(small only) {
                      color: black;
                      background-color: $white;
                    }
                  }
                }
                &:active {
                  @include breakpoint(small only) {
                    color: $black;
                    background-color: $white;
                  }
                }
              }
            }
          }
        }

        a {
          color: $black;
          font-size: rem-calc(18px);
          font-family: $primary-font;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
          position: relative;
          transition: all .5s;

          &:hover {
            background-color: $black;
            color: $white;
            @include breakpoint(small only) {
              color: black;
            }
          }
        }

        &:hover {
          background-color: $black;
          color: $white;
          @include breakpoint(small only) {
            background-color: transparent;
            color: black;
          }

          ul {
            &.sub-menu {
              display: block;
              @include breakpoint(small only) {
                display: none;
              }
            }
          }
        }

        &.current_page_item {
          & > a {
            position: relative;
            background-color: $black;
            color: $white;
            @include breakpoint(small only) {
              background-color: $white;
              color: $black!important;
            }
          }
        }
      }
    }
  }

  &.sticky-header {
    display: none;
    position: fixed;
    top: 0;
    background-color: $white;
    @include breakpoint(small only) {
      display: none;
    }

    .main-header-navigation-with-bg-container {
      padding: 10px 0;

      nav {
        ul {
          margin: 0;

          @include breakpoint(small only) {
            width: 100%;
            display: none;
          }

          li {
            list-style-type: none;
            display: inline-block;

            @include breakpoint(small only) {
              display: block;
              text-align: left;
              width: 89%;
            }

            &.menu-item-has-children {

              &:hover,
              &:focus {
                a {
                  color: $white;
                  @include breakpoint(small only) {
                    color: black!important;
                    background-color: white!important;
                  }
                }

                ul {
                  &.sub-menu {
                    li {
                      a {
                        color: $black;
                        @include breakpoint(small only) {
                          color: black;
                          background-color: white!important;
                        }
                      }


                      &:hover,
                      &:focus {
                        a {
                          color: $white;
                          @include breakpoint(small only) {
                            color: black;
                            background-color: white!important;
                          }
                        }
                      }
                    }
                  }
                }
              }
            }

            a {
              color: $black;
              font-size: rem-calc(16px);
              font-family: $primary-font;
              -webkit-font-smoothing: antialiased;
              -moz-osx-font-smoothing: grayscale;
              position: relative;
              @include breakpoint(small only) {
                font-size: rem-calc(18px);
              }

              &:hover,
              &:focus,
              &:active {
                background-color: $black;
                color: $white;
                @include breakpoint(small only) {
                  background-color: $white;
                  color: $black;
                }
              }
            }

            &:hover,
            &:focus,
            &:active {
              background-color: $black;
              color: $white;
              @include breakpoint(small only) {
                background-color: $white;
                color: $black;
              }

              ul {
                &.sub-menu {
                  display: block;
                }
              }
            }

            &.current_page_item {
              a {
                position: relative;
                color: $white;
                @include breakpoint(small only) {
                  background-color: $white;
                  color: $black;
                }
              }
            }

            /*ul {
              &.sub-menu {
                display: none;
                background-color: $white;
                border: 2px solid $black;
                position: absolute;

                li {
                  display: block;

                  a {
                    font-size: rem-calc(14px);
                    color: $black;
                  }

                  &:hover,
                  &:focus,
                  &:active {
                    a {
                      color: $white;
                    }
                  }
                }
              }
            }*/
          }
        }
      }

      a {
        &.logo {
          img {
            max-height: 40px;
            width: 35px;
          }
        }
      }
    }
  }

  @include breakpoint(small only) {
    &.open-menu {
      background-color: white;
      height: 100vh;

      .main-header-navigation-with-bg-container {
        height: 100vh;
        position: relative;
        padding: 100px 0 20px;

        nav {
          ul {
            li {
              &.menu-item-has-children {
                ul {
                  &.sub-menu {
                    position: relative;
                    border: 0;

                    li {
                      a {
                       padding: 0.5rem 1rem 0.5rem;
                      }
                    }
                  }
                }
              }
            }
          }

        }

        .logo {
          position: absolute;
          bottom: 15%;
          left: 50%;
          transform: translateX(-50%);
        }
      }
    }
  }

  &-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 0;

    @include breakpoint(small only) {
      display: block;
      padding: 9px 0 3px;
    }

    a {
      &.menu-btn {
        position: absolute;
        top: 25px;
        right: 30px;
        z-index: 999;
      }

      &.logo {
        img {
          object-fit: contain;
          object-position: left;
          max-width: 200px;
          max-height: 70px;
          width: 55px;

          @include breakpoint(small only) {
            width: 35px !important;
            margin-bottom: 10px;
            margin-left: 10px;
          }
        }
      }
    }


    /*************** hamburger menu *****************/
    a {
      &.menu-btn {

        &.menu-btn-close {
          .menu__btn {
            span {
              transform: rotate(45deg);

              &:before {
                top: 0;
                transform: rotate(0);
              }

              &:after {
                top: 0;
                transform: rotate(90deg);
              }
            }
          }
        }

        .menu__btn {
          display: flex;
          align-items: center;
          position: fixed;
          top: 20px;
          right: 20px;
          width: 26px;
          height: 26px;

          cursor: pointer;
          z-index: 1;

          span {
            display: block;
            position: absolute;
            width: 100%;
            height: 2px;
            background-color: #616161;
            transition-duration: .25s;

            &:before {
              display: block;
              position: absolute;
              width: 100%;
              height: 2px;
              background-color: #616161;
              transition-duration: .25s;
              content: '';
              top: -8px;
            }

            &:after {
              display: block;
              position: absolute;
              width: 100%;
              height: 2px;
              background-color: #616161;
              transition-duration: .25s;
              content: '';
              top: 8px;
            }
          }
        }
      }
    }


    /*************** hamburger menu end *****************/

  }
}
