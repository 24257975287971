.infoblock {
  height: 400px;
  background-attachment: fixed;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;

  .infoblock_inner {
    padding: rem-calc(30px) rem-calc(80px);
    background-color: rgba(255, 255, 255, 0.6);
  }
}


.posts-view {
  background: #f5f5f5;
  padding: 40px 0 100px;
  @include breakpoint(small only){
    padding: 40px 0 50px;
  }

  &.posts-view-white-bg {
    background-color: $white;
  }

  .more-container {
    margin-top: 50px;
    .front-beans-btn {
      margin-top: 0;
    }
  }

  h2 {
    &.title {
      margin-top: 7%;
      margin-bottom: 30px;

      @include breakpoint(small only) {
        margin-top: 15%;
      }
    }
  }

  .post-thumbnail {
    background-repeat: no-repeat;
    background-size: cover;
    height: 300px;
    background-position: top center;
    background-color: $primary-color;
    margin-bottom: 10px;
  }

  p {
    &.category {
      font-family: $secondary-font;
      font-size: rem-calc(14px);
      padding: 5px;
      border: 1px solid #0a0a0a;
      display: inline-block;
      margin-right: 5px;
    }
  }

  .post-item {
    position: relative;
    height: 265px;
    overflow: hidden;
    margin-bottom: 30px;
    @include breakpoint(small only) {
      height: auto;
      margin-bottom: 70px;
    }

    a {
      text-decoration: none;
    }

    p {
      &.date {
        font-size: rem-calc(16px);
        font-family: $heavy-font;
      }
    }

    .more-btn {
      font-family: $heavy-font;
      color: $black;
      position: absolute;
      bottom: 0;
      margin-left: 40px;

      @include breakpoint(small only) {
       position: relative;
      }

      &:before {
        position: absolute;
        left: -35px;
        top: 16px;
        display: block;
        content: '';
        width: 20px;
        height: 3px;
        background-color: $black;
        transition: height .3s;
      }

      &:hover {
        color: $black;

        &:before {
          height: 6px;
        }
      }
    }
  }

  .more-container {
    display: flex;
    justify-content: center;
    align-items: center;
    @include breakpoint(small only) {
      display: block;
    }

    .beans-btn {
      @include breakpoint(small only) {
        margin: 0 50px;
        display: inline-block;
      }
    }

    .more-btn {
      font-family: $heavy-font;
      color: $black;
      margin-left: 80px;

      @include breakpoint(small only) {
        margin: 0 50px;
        display: inline-block;
      }

      &:before {
        position: absolute;
        left: -35px;
        top: 16px;
        display: block;
        content: '';
        width: 20px;
        height: 3px;
        background-color: $black;
        transition: height .3s;
      }

      &:hover {
        color: $black;

        &:before {
          height: 6px;
        }
      }
    }
  }
}

.discussion {
  padding: 100px 0;
  background-color: #EFEFEF;
  z-index: 99;
  position: relative;

  @include breakpoint(small only){
    padding: 100px 0;
  }

  h2 {
    margin-bottom: rem-calc(50px);
  }

  .content-container {
    p {
      font-family: $heavy-font;
      font-size: rem-calc(24px);
    }

    .btn-container {
      margin-top: 30px;
    }
  }
}

.grid-item {
  width: 200px;
}